const config = {
  // Landing Page
  landingcontent: {
    title:
      "The Sky Bridge at Town Center | Extraordinary Senior Living in Upper Marlboro, Maryland",
    subtitle:
      "A retirement community offering Active Living, Independent Living, Assisted Living and Memory Care.",
    description:
      "Experience extraordinary living at The Sky Bridge at Town Center - The Parc. Find Independent Living, Assisted Living, Memory Care, and Short-Term Stays.",
    keywords:
      "living, senior, new, sky, bridge, center, town, extraordinary, maryland, lifestyle, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Maryland Senior Lifestyle, Maryland Senior Apartments, Senior Wellness Maryland, Personalized Senior Care, Maryland Senior Home, Luxury Senior Living, Active Senior Living Maryland, Independent Living Maryland, TX, Senior Community Maryland, Retirement Homes Maryland, Senior Health Services, Elderly Care Maryland, TX, Senior Housing Maryland, Premier Senior Living Maryland, Senior Assistance Maryland, Senior Support Services, Maryland Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Maryland, Maryland Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Maryland, Senior Independence Maryland, Maryland Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Maryland, Senior Daily Living Maryland, Maryland Senior Programs, Maryland Assisted Living.",
  },

  // About Us Page
  aboutcontent: {
    title:
      "The Sky Bridge at Town Center | Extraordinary Senior Living in Upper Marlboro, Maryland",
    subtitle:
      "A retirement community offering Active Living, Independent Living, Assisted Living and Memory Care.",
    description:
      "Experience extraordinary living at The Sky Bridge at Town Center - The Parc. Find Independent Living, Assisted Living, Memory Care, and Short-Term Stays.",
    keywords:
      "living, senior, new, sky, bridge, center, town, extraordinary, maryland, lifestyle, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Maryland Senior Lifestyle, Maryland Senior Apartments, Senior Wellness Maryland, Personalized Senior Care, Maryland Senior Home, Luxury Senior Living, Active Senior Living Maryland, Independent Living Maryland, TX, Senior Community Maryland, Retirement Homes Maryland, Senior Health Services, Elderly Care Maryland, TX, Senior Housing Maryland, Premier Senior Living Maryland, Senior Assistance Maryland, Senior Support Services, Maryland Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Maryland, Maryland Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Maryland, Senior Independence Maryland, Maryland Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Maryland, Senior Daily Living Maryland, Maryland Senior Programs, Maryland Assisted Living.",
    header: "AJ's Keto Factory Why - Our Mission",
    message:
      "We are a team who seeks direction from the Holy Spirit, led by leaders dedicated to using our time, talent, and money to meet the community's physical and spiritual needs to advance God's purpose.",
  },
  aboutbusiness: {
    paragraph1:
      "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
    paragraph2:
      "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
  },
  littleaboutus: {
    paragraph1:
      "Experience swift satisfaction at AJ's Keto Factory – your hub for mouthwatering, low-carb meals on the fly! Discover a menu brimming with wheat-free, gluten-free, sugar-free, and soy-free options, carefully prepared daily for a fresh and preservative-free feast. But AJ's is more than just a culinary delight; it's a Kingdom Business, guided by the Holy Spirit, committed to meeting the community's needs. Join us for a taste of purpose at AJ's Keto Factory, where flavor and wellness intertwine in every delectable bite!",
    paragraph2:
      "At AJ's, we're redefining on-the-go dining with our commitment to delicious and health-conscious choices. Our menu features an array of low-carb options that are not only convenient but also crafted with precision – wheat-free, gluten-free, sugar-free, and soy-free. Beyond being your destination for a quick and satisfying meal, we're a Kingdom Business, driven by a higher purpose to serve and uplift our community. Join us at AJ's Keto Factory for a flavorful journey where every bite tells a story of wellness and purpose!",
    paragraph3:
      "Savor the essence of guilt-free indulgence at our Keto haven! Delight in a symphony of flavors, from gluten-free and sugar-free to soy-free and wheat-free options. Whether you opt for dine-in luxury or the convenience of curbside pickup and no-contact delivery, our culinary journey awaits. Nestled in St. Charles, Geneva, and South Elgin, our oasis beckons with delectable keto creations made from fresh, natural ingredients. Dive into our gourmet, carb-free offerings, featuring tantalizing keto bakery treats and a medley of satisfying options that redefine the art of healthy dining. Immerse yourself in the essence of the keto lifestyle with our nutrient-rich, high-quality fare, setting the standard for excellence in St. Charles, Geneva, and South Elgin.",
  },

  // Contact Page
  contactcontent: {
    title:
      "The Sky Bridge at Town Center | Extraordinary Senior Living in Upper Marlboro, Maryland",
    description:
      "Experience extraordinary living at The Sky Bridge at Town Center - The Parc. Find Independent Living, Assisted Living, Memory Care, and Short-Term Stays.",
    keywords:
      "living, senior, new, sky, bridge, center, town, extraordinary, maryland, lifestyle, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Maryland Senior Lifestyle, Maryland Senior Apartments, Senior Wellness Maryland, Personalized Senior Care, Maryland Senior Home, Luxury Senior Living, Active Senior Living Maryland, Independent Living Maryland, TX, Senior Community Maryland, Retirement Homes Maryland, Senior Health Services, Elderly Care Maryland, TX, Senior Housing Maryland, Premier Senior Living Maryland, Senior Assistance Maryland, Senior Support Services, Maryland Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Maryland, Maryland Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Maryland, Senior Independence Maryland, Maryland Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Maryland, Senior Daily Living Maryland, Maryland Senior Programs, Maryland Assisted Living.",
    message:
      "Welcome to a world where wholesome, flavorful, and satisfying keto dining takes center stage!",
  },

  // Photo Gallery Page
  gallerycontent: {
    title:
      "The Sky Bridge at Town Center | Extraordinary Senior Living in Upper Marlboro, Maryland",
    description:
      "Experience extraordinary living at The Sky Bridge at Town Center - The Parc. Find Independent Living, Assisted Living, Memory Care, and Short-Term Stays.",
    keywords:
      "living, senior, new, sky, bridge, center, town, extraordinary, maryland, lifestyle, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Maryland Senior Lifestyle, Maryland Senior Apartments, Senior Wellness Maryland, Personalized Senior Care, Maryland Senior Home, Luxury Senior Living, Active Senior Living Maryland, Independent Living Maryland, TX, Senior Community Maryland, Retirement Homes Maryland, Senior Health Services, Elderly Care Maryland, TX, Senior Housing Maryland, Premier Senior Living Maryland, Senior Assistance Maryland, Senior Support Services, Maryland Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Maryland, Maryland Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Maryland, Senior Independence Maryland, Maryland Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Maryland, Senior Daily Living Maryland, Maryland Senior Programs, Maryland Assisted Living.",
    message:
      "Step into our culinary haven, where ambition meets flavor! Explore our gallery of delicious delights at the ambitious deli and grocery store.",
  },

  // Menu Page
  ordercontent: {
    title:
      "The Sky Bridge at Town Center | Extraordinary Senior Living in Upper Marlboro, Maryland",
    description:
      "Experience extraordinary living at The Sky Bridge at Town Center - The Parc. Find Independent Living, Assisted Living, Memory Care, and Short-Term Stays.",
    keywords:
      "living, senior, new, sky, bridge, center, town, extraordinary, maryland, lifestyle, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Maryland Senior Lifestyle, Maryland Senior Apartments, Senior Wellness Maryland, Personalized Senior Care, Maryland Senior Home, Luxury Senior Living, Active Senior Living Maryland, Independent Living Maryland, TX, Senior Community Maryland, Retirement Homes Maryland, Senior Health Services, Elderly Care Maryland, TX, Senior Housing Maryland, Premier Senior Living Maryland, Senior Assistance Maryland, Senior Support Services, Maryland Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Maryland, Maryland Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Maryland, Senior Independence Maryland, Maryland Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Maryland, Senior Daily Living Maryland, Maryland Senior Programs, Maryland Assisted Living.",
    message:
      "Enjoy the convenience of keto delivery and takeout, or visit our keto cafe for fresh and healthy choices. The Sky Bridge at Town Center | Extraordinary Senior Living in Upper Marlboro, Maryland!",
  },

  // Eloyalty Page
  eloyaltycontent: {
    title:
      "Join our eLoyalty Club for exclusive member benefits, specials, and events, earning Bucks for loyalty rewards.",
    description:
      "Experience extraordinary living at The Sky Bridge at Town Center - The Parc. Find Independent Living, Assisted Living, Memory Care, and Short-Term Stays.",
    keywords:
      "living, senior, new, sky, bridge, center, town, extraordinary, maryland, lifestyle, Industry-Leading Senior Service, Supportive Senior Environment, Senior Journey Support, Family Partnering Senior Care, Peace of Mind Senior Care, Thriving Senior Community, Caring Senior Services, Maryland Senior Lifestyle, Maryland Senior Apartments, Senior Wellness Maryland, Personalized Senior Care, Maryland Senior Home, Luxury Senior Living, Active Senior Living Maryland, Independent Living Maryland, TX, Senior Community Maryland, Retirement Homes Maryland, Senior Health Services, Elderly Care Maryland, TX, Senior Housing Maryland, Premier Senior Living Maryland, Senior Assistance Maryland, Senior Support Services, Maryland Senior Care Center, Watermere Senior Services, Compassionate Senior Care, Quality Senior Living, Senior Living Solutions Maryland, Maryland Elder Care, Senior Comfort Care, Watermere Community, Aging with Dignity Maryland, Senior Independence Maryland, Maryland Retirement Living, Holistic Senior Care, Senior Living Facilities, Elderly Support Maryland, Senior Daily Living Maryland, Maryland Senior Programs, Maryland Assisted Living.",
    message:
      "Activate your account now and explore our gourmet, health-conscious menu, packed with natural ingredients and nutritious choices.",
  },
};

export default config;
